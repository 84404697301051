import React, { useEffect, useState } from 'react';
import { getAlist } from '../../services/APIService'; // Adjust the import path as needed

interface Order {
  id: string;
  customerName: string;
  product: string;
  quantity: number;
  amount: number;
  paymentMethod: string;
  orderDate: string;
  estimatedDeliveryDate: string;
  address: string;
  status: string;
}

const AList: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [ordersData, setOrdersData] = useState<Order[]>([]); // State to store orders

  const ordersPerPage = 3; // Number of orders to display per page

  // Function to fetch orders from the API
  const fetchOrders = async () => {
    try {
      const orders = await getAlist(); // Pass the orderId to the API call
      setOrdersData(orders); // Set the fetched orders data
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const listOrders: Order[] = ordersData.filter(order => order.status !== '');

  const filteredOrders = listOrders.filter(order => {
    return (
      order.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.product.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const viewOrder = (order: Order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <h2>Orders Table</h2>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <h5 className="card-header">Orders Table</h5>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Customer Name or Product"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-bordered first">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Customer Name</th>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                        <th>Order Date</th>
                        <th>Date of Delivery</th>
                        <th>Address</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentOrders.map(order => (
                        <tr key={order.id}>
                          <td>{order.id}</td>
                          <td>{order.customerName}</td>
                          <td>{order.product}</td>
                          <td>{order.quantity}</td>
                          <td>{order.amount}</td>
                          <td>{order.paymentMethod}</td>
                          <td>{order.orderDate}</td>
                          <td>{order.estimatedDeliveryDate}</td>
                          <td>{order.address}</td>
                          <td>
                            <button
                              className="btn-info"
                              onClick={() => viewOrder(order)}
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination mt-3">
                  <button onClick={handlePrevPage} disabled={currentPage === 1} className="btn btn-primary">
                    Previous
                  </button>
                  <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages} className="btn btn-primary">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Order Details Modal */}
        {showModal && selectedOrder && (
          <div className="modal show" style={{ display: 'block' }} tabIndex={-1}>
            <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Order Details</h5>
                <button type="button" className="close" onClick={handleClose}>&times;</button>
              </div>
              <div className="modal-body">
                <p><strong>Order ID:</strong> {selectedOrder.id}</p>
                <p><strong>Customer Name:</strong> {selectedOrder.customerName}</p>
                <p><strong>Product:</strong> {selectedOrder.product}</p>
                <p><strong>Quantity:</strong> {selectedOrder.quantity}</p>
                <p><strong>Amount:</strong> {selectedOrder.amount}</p>
                <p><strong>Payment Method:</strong> {selectedOrder.paymentMethod}</p>
                <p><strong>Order Date:</strong> {selectedOrder.orderDate}</p>
                <p><strong>Estimated Delivery Date:</strong> {selectedOrder.estimatedDeliveryDate}</p>
                <p><strong>Address:</strong> {selectedOrder.address}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-secondary" onClick={handleClose}>Close</button>
              </div>
            </div>
          </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AList;

import axios from 'axios';
import { BASE_URL } from "../constants/DataConstant";
import ordersData from '../data/pending.json'; // Adjust the path as needed view the orderlist
import productsData from '../data/products.json'; // Adjust the path as needed add product
import orderData from '../data/orderData.json'; // Importing order data JSON
import salesData from '../data/salesData.json'; // Importing sales data JSON
import inventoryData from '../data/inventoryData.json'; // Importing inventory data JSON



const ax = axios.create({ baseURL: BASE_URL });

export const adminLogin = async (data:any) => {
  let config = {
    method: "post",
    url: `orginfo/adminLogin`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const uploadData = async (data:any) => {
  let config = {
    method: "post",
    url: `student/uploadData`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getAllStudents = async () => {
  let config = {
    method: "get",
    url: `student/getAllStudents`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getStudents = async (data:any) => {
  let config = {
    method: "post",
    url: `/api/User`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getSingleStudent = async (id:any) => {
  let config = {
    method: "get",
    url: `student/getSingleStudent/${id}`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getAlist = async () => {
  // let config = {
  //   method: "get",
  //   url: `orders/getAlist/${id}`,  // Make sure this URL is correct
  //   headers: { "Content-Type": "pending.json" },
  // };
  // const response = await axios.request(config);
  // return response?.data;
  const response = await ordersData;
  return response;
};

export const getAddProduct = async () => {
  // let config = {
  //   method: "get",
  //   url: `orders/getAlist/${id}`,  // Make sure this URL is correct
  //   headers: { "Content-Type": "pending.json" },
  // };
  // const response = await axios.request(config);
  // return response?.data;
  const response = await productsData;
  return response;
};

// Report.tsx
export const getSalesData = async () => {
  // let config = {
  //   method: "get",
  //   url: `orders/getAlist/${id}`,  // Make sure this URL is correct
  //   headers: { "Content-Type": "pending.json" },
  // };
  // const response = await axios.request(config);
  // return response?.data;
  const response = await salesData;
  return response;
};

export const getInventoryData = async () => {
  // let config = {
  //   method: "get",
  //   url: `orders/getAlist/${id}`,  // Make sure this URL is correct
  //   headers: { "Content-Type": "pending.json" },
  // };
  // const response = await axios.request(config);
  // return response?.data;
  const response = await inventoryData;
  return response;
};

export const getOrderData = async () => {
  // let config = {
  //   method: "get",
  //   url: `orders/getAlist/${id}`,  // Make sure this URL is correct
  //   headers: { "Content-Type": "pending.json" },
  // };
  // const response = await axios.request(config);
  // return response?.data;
  const response = await orderData;
  return response;
};

export const sendOTP = async (userData: { email: string; phone: string }) => {
  let config = {
    method: 'post',
    url: `http://localhost:3000/api/User`,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(userData), // Ensure data is sent in JSON format
  };

  const response = await ax.request(config);
  return response?.data; 
  }


  export const createAccount = async (data:any) => {
    console.log(data);
    let config = {
      method: "post",
      url: `${BASE_URL}User`,
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    const response = await ax.request(config);
    return response?.data;
  };


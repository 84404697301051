//export const BASE_URL = "http://127.0.0.1:8080/lcapp_api/";
export const BASE_URL = "https://prodapi.mvp.atopd.in/api/";

// User Credentials Start
// export const USER_ID = JSON.parse(localStorage.getItem("loggedInUser"));
// export const LCDATA = JSON.parse(localStorage.getItem("LCData"));

// Payment Constants Start
// export const ONLINE_ORDER_DETAILS = JSON.parse(
//   localStorage.getItem("OnlineOrderDetails")
// );
// export const PAYMENT_DETAILS = JSON.parse(
//   localStorage.getItem("PaymentDetails")
// );
// export const BOOKING_DETAILS = JSON.parse(
//   localStorage.getItem("BookingDetails")
// );
// export const CONTACT_NUMBER = JSON.parse(
//   localStorage.getItem("contact_number")
// );

// Payment Constants Start
// ATJOIN LIVE ENVIRONMENT START
// export const SALT_KEY = '0d8b9f31-5d27-48a7-9405-d83f80d2944a';
// export const MERCHANT_ID = 'ATJOINONLINE';
// export const API_URL = `${PHONEPE_PLESK_URL}Employee/phonepe`;
// ATJOIN LIVE ENVIRONMENT END

// SANDBOX ENVIRONMENT START
// export const SALT_KEY = "099eb0cd-02cf-4e2a-8aca-3e6c6aff039";
// export const MERCHANT_ID = "PGTESTPAYUAT";
// export const API_URL =
//   "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
// SANDBOX ENVIRONMENT END
// Payment Constants End

// src/components/VendorRequestHandler.tsx
import React, { useState } from 'react';

// Define the Vendor interface
interface Vendor {
  id: string;
  name: string;
  contactNumber: string;
  email: string;
  address: string;
  category: string;
  dateOfRegister: string;
  expiryDate: string;
  status: 'active' | 'inactive';
}

const VendorRequestHandler: React.FC = () => {
  const [vendor, setVendor] = useState<Vendor>({
    id: '',
    name: '',
    contactNumber: '',
    email: '',
    address: '',
    category: '',
    dateOfRegister: '',
    expiryDate: '',
    status: 'active',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setVendor({
      ...vendor,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Vendor data:', vendor);
    // Reset form after submission
    setVendor({
      id: '',
      name: '',
      contactNumber: '',
      email: '',
      address: '',
      category: '',
      dateOfRegister: '',
      expiryDate: '',
      status: 'active',
    });
  };

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <div className="text-center">
        <h2>  Update Vendor Form</h2>
        </div>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-10 col-md-12">
          <div className="card">
            <h5 className="card-header"> Update Vendor Form</h5>
            <div className="card-body">
              <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name">      Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={vendor.name}
                      onChange={handleChange}
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="contactNumber">Contact Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="contactNumber"
                      name="contactNumber"
                      placeholder="Contact Number"
                      value={vendor.contactNumber}
                      onChange={handleChange}
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={vendor.email}
                      onChange={handleChange}
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      name="address"
                      placeholder="Address"
                      value={vendor.address}
                      onChange={handleChange}
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="category">Category</label>
                    <input
                      type="text"
                      className="form-control"
                      id="category"
                      name="category"
                      placeholder="Category"
                      value={vendor.category}
                      onChange={handleChange}
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="dateOfRegister">Date of Register</label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateOfRegister"
                      name="dateOfRegister"
                      value={vendor.dateOfRegister}
                      onChange={handleChange}
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="expiryDate">Expiry Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="expiryDate"
                      name="expiryDate"
                      value={vendor.expiryDate}
                      onChange={handleChange}
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      className="form-control"
                      value={vendor.status}
                      onChange={handleChange}
                      required
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
               
                <div className="row">
                      <div className="col-sm-6 pb-2 pb-sm-4 pb-lg-0 pr-0">
                        <label
                          className="be-checkbox custom-control custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                          /><span className="custom-control-label"
                            >Remember me</span
                          >
                        </label>
                      </div>
                      <div className="col-sm-6 pl-0">
                        <p className="text-right">
                          <button
                            type="submit"
                            className="btn btn-space btn-primary"
                          >
                            Submit
                          </button>
                          <button className="btn btn-space btn-secondary">
                            Cancel
                          </button>
                        </p>
                      </div>
                    </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>    </div>
   
    </div>
  );
};

export default VendorRequestHandler;

import React, { useState } from 'react';
import BusinessVendorDetails from '../dashboard/Form/BusinessDetails';
import BankDetails from '../dashboard/Form/BankDetails';
import KYCDetails from '../dashboard/Form/KYCDetails';
import { Row, Col, ProgressBar } from 'react-bootstrap'; // Import Bootstrap components

interface BusinessData {
  businessName: string;
  businessType: string;
  registrationNumber?: string;
}

interface BankData {
  accountHolderName: string;
  bankAccountNumber: string;
  bankName: string;
  branchName: string;
  ifscCode: string;
  cancelledCheque?: FileList;
}

interface KYCData {
  providerStoreName: string;
  registeredAddress: string;
  email: string;
  mobileNumber: string;
  panNumber: string;
  gstin?: string;
  fssaiNumber?: string;
}

const VendorForm: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [businessData, setBusinessData] = useState<BusinessData | null>(null);
  const [bankData, setBankData] = useState<BankData | null>(null);
  const [kycData, setKycData] = useState<KYCData | null>(null);

  const steps = [
    { label: 'BUSINESS  DETAILS', completed: currentStep >= 0 },
    { label: 'BANK DETAILS', completed: currentStep >= 1 },
    { label: 'KYC DETAILS', completed: currentStep >= 2 },
    { label: 'OnBoarding Process', completed: currentStep >= 3 },
  ];

  const handleBusinessSubmit = (data: BusinessData) => {
    setBusinessData(data);
    setCurrentStep(1); // Move to Bank Details step
  };

  const handleBankSubmit = (data: BankData) => {
    setBankData(data);
    setCurrentStep(2); // Move to KYC Details step
  };

  const handleKYCSubmit = (data: KYCData) => {
    setKycData(data);
    const finalData = {
      ...businessData,
      ...bankData,
      ...data,
    };
    console.log('Final Submission:', finalData);
    // Here you can send `finalData` to your API for submission
    setCurrentStep(3); // Completed all steps
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1); // Go back to the previous step
    }
  };

  const stepStyle = (index: number, completed: boolean): React.CSSProperties => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center' as 'center',
    color: completed ? '#3D3D3D' : '#9E9E9E',
    fontWeight: index === currentStep ? 'bold' : 'normal',
    borderBottom: index === currentStep ? '2px solid #ff7e00' : 'none',
    paddingBottom: '5px',
    width: '200px',
    position: 'relative',
  });

  const circleStyle = (completed: boolean): React.CSSProperties => ({
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: `2px solid ${completed ? '#5e5e5e' : '#9E9E9E'}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    color: completed ? '#5e5e5e' : '#9E9E9E',
    fontSize: '12px',
  });

  const lineStyle: React.CSSProperties = {
    height: '2px',
    width: '40px',
    backgroundColor: '#D3D3D3',
    margin: '0 10px',
  };

  const progressPercentage = (currentStep / (steps.length - 1)) * 100;
  return (
    <div>
      {/* Adjust the margin to push the timeline below the navbar */}
      <div style={{ marginTop: '50px', textAlign: 'center', marginBottom: '20px' ,marginLeft:'250px'}}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {steps.map((step, index) => (
            <div key={index} style={{ display: 'flex',alignItems: 'center' }}>
              <div style={stepStyle(index, step.completed)}>
                <div style={circleStyle(step.completed)}>{step.completed && '✔️'}</div>
                {step.label}
              </div>
              {index < steps.length - 1 && <div style={lineStyle}></div>}
            </div>
          ))}
        </div>
        {/* Progress Bar Row */}
       <Row className="justify-content-center my-4" style={{ justifyContent: 'center'}}>
          <Col md={8}>
            <ProgressBar now={progressPercentage} label={`${Math.round(progressPercentage)}%`} />
          </Col>
        </Row>
      </div>

      {/* Form steps */}
      {currentStep === 0 && <BusinessVendorDetails onSubmit={handleBusinessSubmit} handleBack={handleBack} />}
      {currentStep === 1 && <BankDetails onSubmit={handleBankSubmit} handleBack={handleBack} />}
      {currentStep === 2 && <KYCDetails onSubmit={handleKYCSubmit} handleBack={handleBack} />}

    </div>
  );
};

export default VendorForm;

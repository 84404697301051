import React, { useState, useEffect } from 'react';
import { getAddProduct } from '../../services/APIService'; // Adjust the import path as needed

interface Product {
  id: string;
  name: string;
  price: string;
}

const AddProduct: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAddProductForm, setShowAddProductForm] = useState<boolean>(false);
  const [newProduct, setNewProduct] = useState<Product>({ id: '', name: '', price: '' });

  const productsPerPage = 3;

  const fetchProducts = async () => {
    try {
      const products = await getAddProduct(); // Fetch the list of products using the API
      setProducts(products); // Set the fetched products data
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleAddProduct = () => {
    if (newProduct.id && newProduct.name && newProduct.price) {
      setProducts([...products, newProduct]);
      setNewProduct({ id: '', name: '', price: '' });
      setShowAddProductForm(false);
    } else {
      alert('Please fill in all fields');
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const viewProductDetails = (product: Product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <h2>Product Management</h2>
        </div>

        {showAddProductForm && (
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="card">
                <h5 className="card-header">Add New Product</h5>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="productId">Product ID</label>
                    <input
                      type="text"
                      id="productId"
                      className="form-control"
                      value={newProduct.id}
                      onChange={(e) => setNewProduct({ ...newProduct, id: e.target.value })}
                      placeholder="Enter Product ID"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="productName">Product Name</label>
                    <input
                      type="text"
                      id="productName"
                      className="form-control"
                      value={newProduct.name}
                      onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                      placeholder="Enter Product Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="productPrice">Price</label>
                    <input
                      type="text"
                      id="productPrice"
                      className="form-control"
                      value={newProduct.price}
                      onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                      placeholder="Enter Product Price"
                    />
                  </div>
                  <button className="btn btn-success" onClick={handleAddProduct}>
                    Add Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <h5 className="card-header">Product List</h5>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Product Name"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-bordered first">
                    <thead>
                      <tr>
                        <th>Product ID</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentProducts.map(product => (
                        <tr key={product.id}>
                          <td>{product.id}</td>
                          <td>{product.name}</td>
                          <td>{product.price}</td>
                          <td>
                            <button className="btn btn-info" onClick={() => viewProductDetails(product)}>
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination mt-3">
                  <button onClick={handlePrevPage} disabled={currentPage === 1} className="btn btn-primary">
                    Previous
                  </button>
                  <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages} className="btn btn-primary">
                    Next
                  </button>
                  <span style={{ margin: '0 10px' }} />
                  <button className="btn btn-primary" onClick={() => setShowAddProductForm(!showAddProductForm)}>
                    {showAddProductForm ? 'Cancel' : 'Add Product'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Product Details Modal */}
        {showModal && (
          <div className="modal show" style={{ display: 'block' }} tabIndex={-1}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Product Details</h5>
                  <button type="button" className="close" onClick={handleClose} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {selectedProduct && (
                    <>
                      <p><strong>Product ID:</strong> {selectedProduct.id}</p>
                      <p><strong>Product Name:</strong> {selectedProduct.name}</p>
                      <p><strong>Price:</strong> {selectedProduct.price}</p>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddProduct;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { createAccount } from '../../services/APIService';
import Loader from '../../services/Loader';

interface FormData {
  user_name: string;
  email: string;
  mobile: string;
}

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    try {
      setIsLoading(true);
      const response = await createAccount(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        navigate("/otpVerification");
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error:any) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error?.response?.data?.message);
    }
  };

  return (
    <form className="splash-container" onSubmit={handleSubmit(onSubmit)}>
       {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <h3 className="mb-1">Registration Form</h3>
          <p>Please enter your information.</p>
        </div>
        <div className="card-body">
        {showMsg && (
            <div
              className={msgClass}
              //className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <strong>{msgText}</strong>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          )}
          <div className="form-group">
            <input
              className={`form-control form-control-lg ${errors.user_name ? 'is-invalid' : ''}`}
              type="text"
              placeholder="Name"
              {...register("user_name", { required: 'Username is required' })}
            />
            {errors.user_name && <span className="text-danger">{errors.user_name.message}</span>}
          </div>

          <div className="form-group">
            <input
              className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
              placeholder="E-mail"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Enter a valid email address'
                }
              })}
            />
            {errors.email && <span className="text-danger">{errors.email.message}</span>}
          </div>

         
          <div className="form-group">
            <input
              className={`form-control form-control-lg ${errors.mobile ? 'is-invalid' : ''}`}
              type="text"
              placeholder="Mobile Number"
              maxLength={10}
              {...register('mobile', {
                required: 'Mobile number is required',
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: 'Mobile number must be exactly 10 digits'
                }
              })}
            />
            {errors.mobile && <span className="text-danger">{errors.mobile.message}</span>}
          </div>

         
          <div className="form-group pt-2">
            <button className="btn btn-block btn-primary" type="submit" disabled={isLoading}>
              {isLoading ? 'Processing...' : 'Careate Account'}
            </button>
          </div>

          {/* <div className="form-group">
            <label className="custom-control custom-checkbox">
              <input className="custom-control-input" type="checkbox" />
              <span className="custom-control-label">
                By creating an account, you agree to the{' '}
                <NavLink to="/terms">terms and conditions</NavLink>.
              </span>
            </label>
          </div> */}

        </div>

        <div className="card-footer bg-white">
          <p>
            Already a member? <NavLink to="/login" className="text-secondary">Login Here.</NavLink>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SignUp;

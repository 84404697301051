import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const OtpVerification: React.FC = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(30); // Set initial timer to 30 seconds
  const [canResend, setCanResend] = useState(false); // To disable/enable resend button
  const [otpExpired, setOtpExpired] = useState(false); // Track OTP expiration
  const [otpError, setOtpError] = useState(''); // For custom OTP validation error
  const navigate = useNavigate();
  const location = useLocation();
  const phone = location.state?.phone || 'N/A'; // Fallback if phone is not passed

  // Start timer countdown when the component mounts or timer resets
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(countdown);
    } else {
      setCanResend(true); // Enable resend OTP button after timer reaches 0
      setOtpExpired(true); // Mark OTP as expired
      setError('OTP has expired. Please request a new one.');
    }
  }, [timer]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Custom validation for empty input
    if (!otp) {
      setOtpError('OTP is required.');
      return;
    }

    if (otpExpired) {
      setError('OTP has expired. Please request a new one.');
    } else if (otp === '1234') { // Mock OTP validation
      console.log('OTP verified successfully');
      navigate('/create-new-password'); // Navigate to password creation page
    } else {
      setOtpError('Invalid OTP. Please try again.');
    }
  };

  const handleResendOtp = () => {
    console.log('Resending OTP...');
    setOtp(''); // Clear OTP input
    setError(''); // Clear any previous errors
    setOtpError(''); // Clear OTP validation errors
    setTimer(30); // Reset the timer to 30 seconds
    setCanResend(false); // Disable the resend button
    setOtpExpired(false); // Reset OTP expiration
    // Mock API call to resend OTP
    setTimeout(() => {
      console.log('OTP sent successfully!');
    }, 1000); // Mock delay for OTP resend
  };

  return (
    <form className="splash-container" onSubmit={handleSubmit}>
      <div className="card">
        <div className="card-header">
          <h3 className="mb-1">OTP Verification</h3>
          <p>Enter the OTP sent to {phone}.</p>
        </div>
        <div className="card-body">
          <div className="form-group">
            <input
              className={`form-control form-control-lg ${otpError ? 'is-invalid' : ''}`} // Apply 'is-invalid' class for red border
              type="text"
              name="otp"
              placeholder="Enter OTP"
              autoComplete="off"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
                setOtpError(''); // Reset error when typing
              }}
              maxLength={4}
              disabled={otpExpired} // Disable input if OTP is expired
            />
            {otpError && <span className="text-danger">{otpError}</span>}
          </div>
          <div className="form-group pt-2">
            <button
              className="btn btn-block btn-primary"
              type="submit"
              disabled={otpExpired} // Disable submit button if OTP is expired
            >
              Verify OTP
            </button>
          </div>
          <div className="form-group pt-2 text-center">
            <p>
              {canResend ? (
                <>
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </button>
                  <br />
                  <span className="text-success">You can now resend the OTP!</span> {/* Message after countdown */}
                </>
              ) : (
                <span>Resend OTP in {timer} seconds</span>
              )}
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default OtpVerification;

// import { NavLink, Outlet } from "react-router-dom";

// export default function Dashboard() {


//   return (
//     <div className="dashboard-main-wrapper">
//       <div className="dashboard-header">
//         <nav className="navbar navbar-expand-lg bg-white fixed-top">
//           <NavLink className="navbar-brand" to={"/dashboard"}>
//             Admin Dashboard
//           </NavLink>
//           <button
//             className="navbar-toggler"
//             type="button"
//             data-toggle="collapse"
//             data-target="#navbarSupportedContent"
//             aria-controls="navbarSupportedContent"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <div
//             className="collapse navbar-collapse "
//             id="navbarSupportedContent"
//           >
//             <ul className="navbar-nav ml-auto navbar-right-top">
//               <li className="nav-item dropdown nav-user">
//                 <NavLink
//                   className="nav-link nav-user-img"
//                   to="#"
//                   id="navbarDropdownMenuLink2"
//                   data-toggle="dropdown"
//                   aria-haspopup="true"
//                   aria-expanded="false"
//                 >
//                   <img
//                     src="assets/images/avatar.png"
//                     alt=""
//                     className="user-avatar-md rounded-circle"
//                   />
//                 </NavLink>
//                 <div
//                   className="dropdown-menu dropdown-menu-right nav-user-dropdown"
//                   aria-labelledby="navbarDropdownMenuLink2"
//                 >
//                   <div className="nav-user-info">
//                     <h5 className="mb-0 text-white nav-user-name">
//                       Welcome, User
//                     </h5>
//                     <span className="status"></span>
//                     <span className="ml-2">Available</span>
//                   </div>
//                   {/* <NavLink className="dropdown-item" to="#">
//                     <i className="fas fa-user mr-2"></i>Account
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="#">
//                     <i className="fas fa-cog mr-2"></i>Setting
//                   </NavLink> */}
//                   <NavLink
//                     className="dropdown-item"
//                     to="javascript:void(0);"
//                   >
//                     <i className="fas fa-power-off mr-2"></i>Logout
//                   </NavLink>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </nav>
//       </div>
//       <div className="nav-left-sidebar sidebar-dark">
//         <div className="menu-list">
//           <nav className="navbar navbar-expand-lg navbar-light">
//             <NavLink className="d-xl-none d-lg-none" to="#">
//               Dashboard
//             </NavLink>
//             <button
//               className="navbar-toggler"
//               type="button"
//               data-toggle="collapse"
//               data-target="#navbarNav"
//               aria-controls="navbarNav"
//               aria-expanded="false"
//               aria-label="Toggle navigation"
//             >
//               <span className="navbar-toggler-icon"></span>
//             </button>
//             <div className="collapse navbar-collapse" id="navbarNav">
//               <ul className="navbar-nav flex-column">
//                 {/* <li className="nav-divider">Menu</li> */}
//                 <li className="nav-item ">
//                   <NavLink className="nav-link" to={"/dashboard"}>
//                     <i className="fa fa-fw fa-home"></i>Dashboard
//                     {/* <span className="badge badge-success">6</span> */}
//                   </NavLink>
//                 </li>
//                 <li className="nav-item ">
//                   <NavLink className="nav-link" to={"/dashboard/1"}>
//                     <i className="fa fa-fw fa-arrow-up"></i>Admin Management
//                     {/* <span className="badge badge-success">6</span> */}
//                   </NavLink>
//                 </li>
//                 <li className="nav-item ">
//                   <NavLink className="nav-link" to={"/dashboard/2"}>
//                     <i className="fa fa-fw fa-table"></i>Customer Management
//                   </NavLink>
//                 </li>
//                 <li className="nav-item ">
//                   <NavLink className="nav-link" to={"/dashboard/vender"}>
//                     <i className="fa fa-fw fa-tag"></i>Vendor  Management           
//                    </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink
//                     className="nav-link"
//                     to="javascript:void(0);"
//                   >
//                     <i className="fa fa-fw fa-power-off"></i>Logout
//                   </NavLink>
//                 </li>
               
//               </ul>
//             </div>
//           </nav>
//         </div>
//       </div>

//       <div className="dashboard-wrapper">
//         <Outlet />

//         <div className="footer">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//                 Copyright © 2024-2025 . All rights reserved by{" "}
//                 <NavLink target="_blank" to="https://atjoin.in/">
//                   ATJOIN PVT. LTD.
//                 </NavLink>
//                 .
//               </div>
            
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import { Link ,Outlet} from 'react-router-dom'

export const Dashboard = () => {
  return (

	// <!-- main wrapper -->
        <div className="dashboard-main-wrapper">
			        {/* <!-- navbar --> */}
            <div className="dashboard-header">
	        <nav className="navbar navbar-expand-lg bg-white fixed-top">
	            <Link className="navbar-brand" to="index.html">She Leads</Link>
	            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
	                <span className="navbar-toggler-icon"></span>
	            </button>
	            <div className="collapse navbar-collapse " id="navbarSupportedContent">
	                <ul className="navbar-nav ml-auto navbar-right-top">
	                    <li className="nav-item">
	                        <div id="custom-search" className="top-search-bar">
	                            <input className="form-control" type="text" placeholder="Search.."/>
	                        </div>
	                    </li>
	                    <li className="nav-item dropdown notification">
	                        <Link className="nav-link nav-icons" to="#" id="navbarDropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-fw fa-bell"></i> <span className="indicator"></span></Link>
	                        <ul className="dropdown-menu dropdown-menu-right notification-dropdown">
	                            <li>
	                                <div className="notification-title"> Notification</div>
	                                <div className="notification-list">
	                                    <div className="list-group">
	                                        <Link to="#" className="list-group-item list-group-item-action active">
	                                            <div className="notification-info">
	                                                <div className="notification-list-user-img"><img src="assets/images/avatar-2.jpg" alt="" className="user-avatar-md rounded-circle"/></div>
	                                                <div className="notification-list-user-block"><span className="notification-list-user-name">Jeremy Rakestraw</span>accepted your invitation to join the team.
	                                                    <div className="notification-date">2 min ago</div>
	                                                </div>
	                                            </div>
	                                        </Link>
	                                        <Link to="#" className="list-group-item list-group-item-action">
	                                            <div className="notification-info">
	                                                <div className="notification-list-user-img"><img src="assets/images/avatar-3.jpg" alt="" className="user-avatar-md rounded-circle"/></div>
	                                                <div className="notification-list-user-block"><span className="notification-list-user-name">John Abraham </span>is now following you
	                                                    <div className="notification-date">2 days ago</div>
	                                                </div>
	                                            </div>
	                                        </Link>
	                                        <Link to="#" className="list-group-item list-group-item-action">
	                                            <div className="notification-info">
	                                                <div className="notification-list-user-img"><img src="assets/images/avatar-4.jpg" alt="" className="user-avatar-md rounded-circle"/></div>
	                                                <div className="notification-list-user-block"><span className="notification-list-user-name">Monaan Pechi</span> is watching your main repository
	                                                    <div className="notification-date">2 min ago</div>
	                                                </div>
	                                            </div>
	                                        </Link>
	                                        <Link to="#" className="list-group-item list-group-item-action">
	                                            <div className="notification-info">
	                                                <div className="notification-list-user-img"><img src="assets/images/avatar-5.jpg" alt="" className="user-avatar-md rounded-circle"/></div>
	                                                <div className="notification-list-user-block"><span className="notification-list-user-name">Jessica Caruso</span>accepted your invitation to join the team.
	                                                    <div className="notification-date">2 min ago</div>
	                                                </div>
	                                            </div>
	                                        </Link>
	                                    </div>
	                                </div>
	                            </li>
	                            <li>
	                                <div className="list-footer"> <Link to="#">View all notifications</Link></div>
	                            </li>
	                        </ul>
	                    </li>
	                    <li className="nav-item dropdown connection">
	                        <Link className="nav-link" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="fas fa-fw fa-th"></i> </Link>
	                        <ul className="dropdown-menu dropdown-menu-right connection-dropdown">
	                            <li className="connection-list">
	                                <div className="row">
	                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
	                                        <Link to="#" className="connection-item"><img src="assets/images/github.png" alt="" /> <span>Github</span></Link>
	                                    </div>
	                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
	                                        <Link to="#" className="connection-item"><img src="assets/images/dribbble.png" alt="" /> <span>Dribbble</span></Link>
	                                    </div>
	                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
	                                        <Link to="#" className="connection-item"><img src="assets/images/dropbox.png" alt="" /> <span>Dropbox</span></Link>
	                                    </div>
	                                </div>
	                                <div className="row">
	                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
	                                        <Link to="#" className="connection-item"><img src="assets/images/bitbucket.png" alt=""/> <span>Bitbucket</span></Link>
	                                    </div>
	                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
	                                        <Link to="#" className="connection-item"><img src="assets/images/mail_chimp.png" alt="" /><span>Mail chimp</span></Link>
	                                    </div>
	                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
	                                        <Link to="#" className="connection-item"><img src="assets/images/slack.png" alt="" /> <span>Slack</span></Link>
	                                    </div>
	                                </div>
	                            </li>
	                            <li>
	                                <div className="conntection-footer"><Link to="#">More</Link></div>
	                            </li>
	                        </ul>
	                    </li>
	                    <li className="nav-item dropdown nav-user">
	                        <Link className="nav-link nav-user-img" to="#" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="assets/images/avatar-1.jpg" alt="" className="user-avatar-md rounded-circle"/></Link>
	                        <div className="dropdown-menu dropdown-menu-right nav-user-dropdown" aria-labelledby="navbarDropdownMenuLink2">
	                            <div className="nav-user-info">
	                                <h5 className="mb-0 text-white nav-user-name">John Doe </h5>
	                                <span className="status"></span><span className="ml-2">Available</span>
	                            </div>
	                            <Link className="dropdown-item" to="AccountSetting"><i className="fas fa-user mr-2"></i>Account</Link>
	                            <Link className="dropdown-item" to="Settings"><i className="fas fa-cog mr-2"></i>Setting</Link>
	                            <Link className="dropdown-item" to="#"><i className="fas fa-power-off mr-2"></i>Logout</Link>
	                        </div>
	                    </li>
	                </ul>
	            </div>
	        </nav>
	    </div>

		{/* //end navbar */}

		{/* //start left sidebar */}
		<div className="nav-left-sidebar sidebar-dark">
            <div className="menu-list">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <Link className="d-xl-none d-lg-none" to="home">Dashboard</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex-column">
                            <li className="nav-divider">
                                Menu
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link active" to ="home" data-toggle="collapse" aria-expanded="false" data-target="#submenu-1" aria-controls="submenu-1"><i className="fa fa-fw fa-user-circle"></i>Dashboard <span className="badge badge-success">6</span></Link>
                             <div id="submenu-1" className="collapse submenu" > 
                                     <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-1-2" aria-controls="submenu-1-2">E-Commerce</Link>
                                            <div id="submenu-1-2" className="collapse submenu" >
                                                <ul className="nav flex-column">
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="index.html">E Commerce Dashboard</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="Report">Product List</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="ecommerce-product-single.html">Product Single</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="ecommerce-product-checkout.html">Product Checkout</Link>
                                                    </li> 
                                                </ul>
                                            </div>
                                        </li> 
                                        <li className="nav-item">
                                            <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-1-1" aria-controls="submenu-1-1">Infulencer</Link>
                                            <div id="submenu-1-1" className="collapse submenu">
                                                <ul className="nav flex-column">
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="dashboard-influencer.html">Influencer</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="influencer-finder.html">Influencer Finder</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="influencer-profile.html">Influencer Profile</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-2" aria-controls="submenu-2"><i className="fa fa-fw fa-rocket"></i>Order Management</Link>
                                <div id="submenu-2" className="collapse submenu">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="alist">Order List <span className="badge badge-secondary">New</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="order">Order Fulfillment</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="cancelled-order">Order Cancelled</Link>
                                        </li>
                                       
                                        <li className="nav-item">
                                            <Link className="nav-link" to="PendingOrder">Pending Orders</Link>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <Link className="nav-link" to="refundorder">Order Refund </Link>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-3" aria-controls="submenu-3"><i className="fas fa-fw fa-chart-pie"></i>Vendor management</Link>
                                <div id="submenu-3" className="collapse submenu">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="Vendor">Vendor List</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="Vendorform">Add Vendor</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="Vendor-request-handler">Vendor Update</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="pages/chart-morris.html">Vendor Request</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="vendorForm">Vendor Form</Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link className="nav-link" to="pages/chart-sparkline.html">Sparkline</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="pages/chart-gauge.html">Guage</Link>
                                        </li> */}
                                        {/* <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-3" aria-controls="submenu-3"><i className="fas fa-fw fa-chart-pie"></i>Customer</Link> */}
                                        <div id="submenu-3" className="collapse submenu"> 
                                        <ul className="nav flex-column"> 
                                        <li className="nav-item">
                                            <Link className="nav-link" to="Vendorform">Add Vendor</Link>
                                        </li>
                                        </ul>
                                        </div>
                                        
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-4" aria-controls="submenu-4"><i className="fab fa-fw fa-wpforms"></i>Product management</Link>
                                <div id="submenu-4" className="collapse submenu" >
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="ProductList">Product List</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="AddProduct">Add product </Link>
                                        </li>
                                      
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-7" aria-controls="submenu-7"><i className="fab fa-fw fa-wpforms"></i>Customer management</Link>
                                <div id="submenu-7" className="collapse submenu" >
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="wishlist">Wishlist</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="savingCard">Saving Card </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-5" aria-controls="submenu-5"><i className="fas fa-fw fa-table"></i>Coupon</Link>
                                <div id="submenu-5" className="collapse submenu">
                                    <ul className="nav flex-column">
                                       
                                        <li className="nav-item">
                                            <Link className="nav-link" to="Coupondashboard">Add Coupon</Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                           
                             <li className="nav-item">
                                <Link className="nav-link" to="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-6" aria-controls="submenu-6"><i className="fas fa-fw fa-file"></i>Inventory Management </Link>
                                <div id="submenu-6" className="collapse submenu">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="InventoryManagement">Inventory</Link>
                                        </li>
                                      
                                     
                                    </ul>
                                </div> 
                            </li>   
                        </ul>
                    </div>
                </nav>
            </div>
        </div>

		{/* //end sidebar */}
        <Outlet />

    </div>

  )
}

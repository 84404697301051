import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

interface BankDetailsFormData {
  accountHolderName: string;
  bankAccountNumber: string;
  bankName: string;
  branchName: string;
  ifscCode: string;
  cancelledCheque?: FileList; // Match type with BankData
}

interface BankDetailsProps {
  onSubmit: SubmitHandler<BankDetailsFormData>;
  defaultValues?: BankDetailsFormData;
  handleBack?: () => void; // Added prop for 'Back' button
}

const BankDetails: React.FC<BankDetailsProps> = ({ onSubmit, defaultValues, handleBack }) => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm<BankDetailsFormData>({ defaultValues });
  const cancelledCheque = watch('cancelledCheque'); // Watch file input changes

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // You can handle the file input change here if needed
    const files = e.target.files;
    console.log(files); // For debugging, you might want to process the file list
  };

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <div className="row">
          {/* <h2 className="col-12"></h2> */}
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <h2 className="card-header" style={{ textAlign: 'center' }}>Bank Details</h2>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label htmlFor="accountHolderName">Account Holder Name</label>
                    <input
                      id="accountHolderName"
                      {...register('accountHolderName', { required: 'Account holder name is required' })}
                      className={`form-control ${errors.accountHolderName ? 'is-invalid' : ''}`}
                    />
                    {errors.accountHolderName && <div className="invalid-feedback">{errors.accountHolderName.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="bankAccountNumber">Bank Account Number</label>
                    <input
                      id="bankAccountNumber"
                      {...register('bankAccountNumber', { required: 'Bank account number is required' })}
                      className={`form-control ${errors.bankAccountNumber ? 'is-invalid' : ''}`}
                    />
                    {errors.bankAccountNumber && <div className="invalid-feedback">{errors.bankAccountNumber.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="bankName">Bank Name</label>
                    <input
                      id="bankName"
                      {...register('bankName', { required: 'Bank name is required' })}
                      className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
                    />
                    {errors.bankName && <div className="invalid-feedback">{errors.bankName.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="branchName">Branch Name</label>
                    <input
                      id="branchName"
                      {...register('branchName', { required: 'Branch name is required' })}
                      className={`form-control ${errors.branchName ? 'is-invalid' : ''}`}
                    />
                    {errors.branchName && <div className="invalid-feedback">{errors.branchName.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="ifscCode">IFSC Code</label>
                    <input
                      id="ifscCode"
                      {...register('ifscCode', { required: 'IFSC code is required' })}
                      className={`form-control ${errors.ifscCode ? 'is-invalid' : ''}`}
                    />
                    {errors.ifscCode && <div className="invalid-feedback">{errors.ifscCode.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="cancelledCheque">Cancelled Cheque</label>
                    <input
                      id="cancelledCheque"
                      type="file"
                      {...register('cancelledCheque', { required: 'It is required feild' })}
                      className={`form-control ${errors.cancelledCheque ? 'is-invalid' : ''}`}
                      onChange={handleFileChange}
                    />
                    {errors.cancelledCheque && <div className="invalid-feedback">{errors.cancelledCheque.message}</div>}
                  </div>

                  <div className="button-group mt-3">
                    {handleBack && (
                      <button type="button" onClick={handleBack} className="btn btn-secondary" style={{ marginRight: '10px' }}>
                        Back
                      </button>
                    )}
                    <button type="submit" className="btn btn-success">
                      Next
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;

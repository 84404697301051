import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Address {
  id: number;
  address: string;
  city: string;
  state: string;
  zip: string;
}

const AccountSetting: React.FC = () => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [newAddress, setNewAddress] = useState<Address>({
    id: 0,
    address: '',
    city: '',
    state: '',
    zip: ''
  });

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/customer/address');
      setAddresses(response.data);
    } catch (err) {
      setError('Failed to fetch addresses. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAddress = async () => {
    setError(null);
    try {
      if (selectedAddress) {
        const response = await axios.put(`/api/customer/address/${selectedAddress.id}`, newAddress);
        setAddresses(addresses.map(addr => (addr.id === selectedAddress.id ? response.data : addr)));
      } else {
        const response = await axios.post('/api/customer/address', newAddress);
        setAddresses([...addresses, response.data]);
      }
      setShowModal(false);
      setNewAddress({ id: 0, address: '', city: '', state: '', zip: '' });
    } catch (err) {
      setError('Failed to save address. Please try again.');
    }
  };

  const handleDeleteAddress = async (id: number) => {
    try {
      await axios.delete(`/api/customer/address/${id}`);
      setAddresses(addresses.filter(addr => addr.id !== id));
    } catch (err) {
      setError('Failed to delete address. Please try again.');
    }
  };

  const handleEditClick = (address: Address) => {
    setSelectedAddress(address);
    setNewAddress(address);
    setShowModal(true);
  };

  const handleAddClick = () => {
    setSelectedAddress(null);
    setNewAddress({ id: 0, address: '', city: '', state: '', zip: '' });
    setShowModal(true);
  };

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <h2>Manage Addresses</h2>
        {loading && <div>Loading...</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        <button onClick={handleAddClick} className="btn btn-primary mb-3">
          Add New Address
        </button>
        <ul className="list-group">
          {addresses.map(address => (
            <li key={address.id} className="list-group-item">
              <div>
                <p>{address.address}, {address.city}, {address.state}, {address.zip}</p>
                <button onClick={() => handleEditClick(address)} className="btn btn-warning mr-2">
                  Edit
                </button>
                <button onClick={() => handleDeleteAddress(address.id)} className="btn btn-danger">
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>

        {showModal && (
          <div className="modal" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{selectedAddress ? 'Edit Address' : 'Add New Address'}</h5>
                  <button type="button" className="close" onClick={() => setShowModal(false)}>
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newAddress.address}
                      onChange={(e) => setNewAddress({ ...newAddress, address: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newAddress.city}
                      onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newAddress.state}
                      onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label>ZIP Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newAddress.zip}
                      onChange={(e) => setNewAddress({ ...newAddress, zip: e.target.value })}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary" onClick={handleSaveAddress}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountSetting;

import React, { useState } from 'react';

interface Coupon {
  id: string;
  code: string;
  discountPercentage: number;
  startDate: Date;
  expiryDate: Date;
}

const CouponDashboard: React.FC = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [newCoupon, setNewCoupon] = useState<Omit<Coupon, 'id'>>({
    code: '',
    discountPercentage: 0,
    startDate: new Date(),
    expiryDate: new Date(),
  });
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setNewCoupon(prevCoupon => ({
      ...prevCoupon,
      [name]: name === 'discountPercentage' ? Number(value) : value,
    }));
  };

  const handleDateChange = (name: string, date: Date) => {
    setNewCoupon(prevCoupon => ({
      ...prevCoupon,
      [name]: date,
    }));
  };

  const addCoupon = () => {
    // Check if all required fields are filled
    if (!newCoupon.code || !newCoupon.startDate || !newCoupon.expiryDate) {
      setError('All fields are required.');
      return;
    }

    // Validate the discount percentage
    if (newCoupon.discountPercentage <= 0) {
      setError('Coupon code is invalid. Discount percentage must be greater than 0.');
      return;
    }

    // Validate that the start date is before the expiry date
    if (newCoupon.startDate >= newCoupon.expiryDate) {
      setError('Start date must be before the expiry date.');
      return;
    }

    const id = Date.now().toString();
    const coupon: Coupon = { id, ...newCoupon };
    setCoupons(prevCoupons => [...prevCoupons, coupon]);
    setNewCoupon({
      code: '',
      discountPercentage: 0,
      startDate: new Date(),
      expiryDate: new Date(),
    });
    setError(null);
  };

  const removeCoupon = (id: string) => {
    setCoupons(prevCoupons => prevCoupons.filter(coupon => coupon.id !== id));
  };

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <h2>Add Coupon</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form className="mb-4">
          <div className="form-group">
          <label htmlFor="cuponCode">Coupon Code :</label>
            <input
              type="text"
              name="code"
              value={newCoupon.code}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Coupon Code"
              required={true}
            />
          </div>
          <div className="form-group">
            <label htmlFor="discountPercentage">Discount Percentage :</label>
            <input
              type="number"
              name="discountPercentage"
              value={newCoupon.discountPercentage}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Discount Percentage"
              required={true}
            />
          </div>
          <div className="form-group">
          <label htmlFor="startDate">Start Date :</label>
            <input
              type="date"
              name="startDate"
              value={newCoupon.startDate.toISOString().split('T')[0]}
              onChange={e => handleDateChange('startDate', new Date(e.target.value))}
              className="form-control"
              placeholder="Start Date"
              required={true}
            />
          </div>
          <div className="form-group">
          <label htmlFor="expiryDate">Expiry Date :</label>
            <input
              type="date"
              name="expiryDate"
              value={newCoupon.expiryDate.toISOString().split('T')[0]}
              onChange={e => handleDateChange('expiryDate', new Date(e.target.value))}
              className="form-control"
              placeholder="Expiry Date"
              required={true}
            />
          </div>
          <div className="text-center">
            <button type="button" onClick={addCoupon} className="btn btn-primary">
              Add Coupon
            </button>
          </div>
        </form>

        <h2>Available Coupons</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Coupon Code</th>
              <th>Discount Percentage</th>
              <th>Start Date</th>
              <th>Expiry Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {coupons.map(coupon => (
              <tr key={coupon.id}>
                <td>{coupon.code}</td>
                <td>{coupon.discountPercentage}%</td>
                <td>{coupon.startDate.toDateString()}</td>
                <td>{coupon.expiryDate.toDateString()}</td>
                <td>
                  <button onClick={() => removeCoupon(coupon.id)} className="btn btn-danger">
                    &times;
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponDashboard;

import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

interface BusinessVendorFormData {
  businessName: string;
  registrationNumber?: string;
  businessType: string;
  businessAddress: string;
  businessEmail: string;
  businessPhoneNumber: string;
}

interface BusinessVendorDetailsProps {
  onSubmit: SubmitHandler<BusinessVendorFormData>;
  defaultValues?: BusinessVendorFormData;
  handleBack?: () => void;
}

const BusinessVendorDetails: React.FC<BusinessVendorDetailsProps> = ({ onSubmit, defaultValues, handleBack }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<BusinessVendorFormData>({ defaultValues });

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <div className="row">
          {/* <h2 className="col-12"></h2> */}
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <h2 className="card-header" style={{ textAlign: 'center' }}>Business Vendor Details</h2>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label htmlFor="businessName">Business Name</label>
                    <input
                      id="businessName"
                      {...register('businessName', { required: 'Business name is required' })}
                      className={`form-control ${errors.businessName ? 'is-invalid' : ''}`}
                    />
                    {errors.businessName && <div className="invalid-feedback">{errors.businessName.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="registrationNumber">Business Registration Number (if applicable)</label>
                    <input
                      id="registrationNumber"
                      {...register('registrationNumber')}
                      className={`form-control ${errors.registrationNumber ? 'is-invalid' : ''}`}
                    />
                    {errors.registrationNumber && <div className="invalid-feedback">{errors.registrationNumber.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="businessType">Business Type</label>
                    <select
                      id="businessType"
                      {...register('businessType', { required: 'Business type is required' })}
                      className={`form-control ${errors.businessType ? 'is-invalid' : ''}`}
                    >
                      <option value="">Select Business Type</option>
                      <option value="Sole Proprietor">Sole Proprietor</option>
                      <option value="LLC">LLC</option>
                      <option value="Corporation">Corporation</option>
                    </select>
                    {errors.businessType && <div className="invalid-feedback">{errors.businessType.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="businessAddress">Business Address</label>
                    <input
                      id="businessAddress"
                      {...register('businessAddress', { required: 'Business address is required' })}
                      className={`form-control ${errors.businessAddress ? 'is-invalid' : ''}`}
                    />
                    {errors.businessAddress && <div className="invalid-feedback">{errors.businessAddress.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="businessEmail">Business Email</label>
                    <input
                      id="businessEmail"
                      type="email"
                      {...register('businessEmail', { required: 'Business email is required' })}
                      className={`form-control ${errors.businessEmail ? 'is-invalid' : ''}`}
                    />
                    {errors.businessEmail && <div className="invalid-feedback">{errors.businessEmail.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="businessPhoneNumber">Business Phone Number</label>
                    <input
                      id="businessPhoneNumber"
                      {...register('businessPhoneNumber', { required: 'Business phone number is required' })}
                      className={`form-control ${errors.businessPhoneNumber ? 'is-invalid' : ''}`}
                    />
                    {errors.businessPhoneNumber && <div className="invalid-feedback">{errors.businessPhoneNumber.message}</div>}
                  </div>

                  <div className="button-group mt-3">
                    {handleBack && (
                      <button type="button" onClick={handleBack} className="btn btn-secondary"
                      style={{ marginRight: '10px' }} // Add margin-right to the Back button
                      >
                        Back
                      </button>
                    )}
                    <button type="submit" className="btn btn-success">
                      Next
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessVendorDetails;

// import React from 'react';
// import { useForm, SubmitHandler } from 'react-hook-form';

// interface BusinessVendorFormData {
//   businessName: string;
//   registrationNumber?: string;
//   businessType: string;
//   businessAddress: string;
//   businessEmail: string;
//   businessPhoneNumber: string;
// }

// interface BusinessVendorDetailsProps {
//   onSubmit: SubmitHandler<BusinessVendorFormData>;
//   defaultValues?: BusinessVendorFormData;
//   handleBack?: () => void;
// }

// const BusinessVendorDetails: React.FC<BusinessVendorDetailsProps> = ({ onSubmit, defaultValues, handleBack }) => {
//   const { register, handleSubmit, formState: { errors } } = useForm<BusinessVendorFormData>({ defaultValues });

//   return (
//     <div className="dashboard-wrapper">
//       <div className="container-fluid dashboard-content">
//         <div className="row justify-content-center">
//           <div className="col-xl-8 col-lg-10 col-md-12">
//             <div className="card">
//               <h2 className="card-header text-center">Business Vendor Details</h2>
//               <div className="card-body">
//                 <form onSubmit={handleSubmit(onSubmit)}>
//                   <div className="form-row">
//                     <div className="form-group col-md-6">
//                       <label htmlFor="businessName">Business Name</label>
//                       <input
//                         id="businessName"
//                         {...register('businessName', { required: 'Business name is required' })}
//                         className={`form-control ${errors.businessName ? 'is-invalid' : ''}`}
//                       />
//                       {errors.businessName && <div className="invalid-feedback">{errors.businessName.message}</div>}
//                     </div>

//                     <div className="form-group col-md-6">
//                       <label htmlFor="registrationNumber">Business Registration Number (if applicable)</label>
//                       <input
//                         id="registrationNumber"
//                         {...register('registrationNumber')}
//                         className={`form-control ${errors.registrationNumber ? 'is-invalid' : ''}`}
//                       />
//                       {errors.registrationNumber && <div className="invalid-feedback">{errors.registrationNumber.message}</div>}
//                     </div>
//                   </div>

//                   <div className="form-row">
//                     <div className="form-group col-md-6">
//                       <label htmlFor="businessType">Business Type</label>
//                       <select
//                         id="businessType"
//                         {...register('businessType', { required: 'Business type is required' })}
//                         className={`form-control ${errors.businessType ? 'is-invalid' : ''}`}
//                       >
//                         <option value="">Select Business Type</option>
//                         <option value="Sole Proprietor">Sole Proprietor</option>
//                         <option value="LLC">LLC</option>
//                         <option value="Corporation">Corporation</option>
//                       </select>
//                       {errors.businessType && <div className="invalid-feedback">{errors.businessType.message}</div>}
//                     </div>

//                     <div className="form-group col-md-6">
//                       <label htmlFor="businessPhoneNumber">Business Phone Number</label>
//                       <input
//                         id="businessPhoneNumber"
//                         {...register('businessPhoneNumber', { required: 'Business phone number is required' })}
//                         className={`form-control ${errors.businessPhoneNumber ? 'is-invalid' : ''}`}
//                       />
//                       {errors.businessPhoneNumber && <div className="invalid-feedback">{errors.businessPhoneNumber.message}</div>}
//                     </div>
//                   </div>

//                   <div className="form-row">
//                     <div className="form-group col-md-6">
//                       <label htmlFor="businessEmail">Business Email</label>
//                       <input
//                         id="businessEmail"
//                         type="email"
//                         {...register('businessEmail', { required: 'Business email is required' })}
//                         className={`form-control ${errors.businessEmail ? 'is-invalid' : ''}`}
//                       />
//                       {errors.businessEmail && <div className="invalid-feedback">{errors.businessEmail.message}</div>}
//                     </div>

//                     <div className="form-group col-md-6">
//                       <label htmlFor="businessAddress">Business Address</label>
//                       <input
//                         id="businessAddress"
//                         {...register('businessAddress', { required: 'Business address is required' })}
//                         className={`form-control ${errors.businessAddress ? 'is-invalid' : ''}`}
//                       />
//                       {errors.businessAddress && <div className="invalid-feedback">{errors.businessAddress.message}</div>}
//                     </div>
//                   </div>

//                   <div className="button-group mt-3">
//                     {handleBack && (
//                       <button type="button" onClick={handleBack} className="btn btn-secondary" style={{ marginRight: '10px' }}>
//                         Back
//                       </button>
//                     )}
//                     <button type="submit" className="btn btn-success">
//                       Next
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BusinessVendorDetails;

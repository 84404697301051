import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Product {
  id: string;
  name: string;
  category: string;
  price: string;
}

const Wishlist: React.FC = () => {
  const [wishlist, setWishlist] = useState<Product[]>([]);
  const [newProductId, setNewProductId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    fetchWishlist();
  }, []);

  const fetchWishlist = async () => {
    try {
      const response = await axios.get('/api/customer/wishlist');
      setWishlist(response.data);
    } catch (error) {
      setErrorMessage('Failed to retrieve wishlist. Please try again later.');
    }
  };

  const addProductToWishlist = async () => {
    try {
      const response = await axios.post('/api/customer/wishlist', { productId: newProductId });
      if (response.status === 201) {
        fetchWishlist(); // Refresh wishlist after adding a product
        setNewProductId(''); // Clear input field
      }
    } catch (error) {
      setErrorMessage('Failed to add product to wishlist. Please try again.');
    }
  };

  const removeProductFromWishlist = async (productId: string) => {
    try {
      const response = await axios.delete(`/api/customer/wishlist/${productId}`);
      if (response.status === 200) {
        fetchWishlist(); // Refresh wishlist after removing a product
      }
    } catch (error) {
      setErrorMessage('Failed to remove product from wishlist. Please try again.');
    }
  };

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <h2>My Wishlist</h2>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        
        <form className="mb-4">
          <div className="form-group">
            <h5>Add a Product</h5>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Product ID"
              value={newProductId}
              onChange={(e) => setNewProductId(e.target.value)}
              required
            />
          </div>
          <div className="text-center">
            <button className="btn btn-primary mt-2" type="button" onClick={addProductToWishlist}>
              Add to Wishlist
            </button>
          </div>
        </form>

        <h2>Available Wishlist Products</h2>
        <div className="table-responsive">
          <table className="table table-striped table-bordered first">
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Product Name</th>
                <th>Category</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {wishlist.map((product) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.name}</td>
                  <td>{product.category}</td>
                  <td>{product.price}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => removeProductFromWishlist(product.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;

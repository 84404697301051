import React, { useState } from 'react';

// Define the KYCDetails interface with only the required fields
interface KYCDetails {
  uploadDocuments: {
    aadharCard?: File | null;
    panCard?: File | null;
    gstinCertificate?: File | null;
    cancelledCheque?: File | null;
  };
}

const KYC: React.FC = () => {
  const [formData, setFormData] = useState<KYCDetails>({
    uploadDocuments: {
      aadharCard: null,
      panCard: null,
      gstinCertificate: null,
      cancelledCheque: null,
    },
  });

  const [selectedDocumentType, setSelectedDocumentType] = useState<string>('');

  // Handle input changes for file uploads
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const allowedTypes = [
      'application/pdf', 
      'application/msword', 
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
      'image/jpeg', 
      'image/png'
    ];
    const maxSize = 256 * 1024; // 256 KB in bytes

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        alert('Invalid file type. Please upload a PDF, DOC/DOCX, or image file.');
      } else if (file.size > maxSize) {
        alert('File size exceeds 256 KB. Please upload a smaller file.');
      } else {
        setFormData(prevData => ({
          ...prevData,
          uploadDocuments: {
            ...prevData.uploadDocuments,
            [e.target.name]: file
          }
        }));
      }
    }
  };

  const handleCancel = (documentType: keyof KYCDetails['uploadDocuments']) => {
    setFormData(prevData => ({
      ...prevData,
      uploadDocuments: {
        ...prevData.uploadDocuments,
        [documentType]: null,
      },
    }));
    if (selectedDocumentType === documentType) {
      setSelectedDocumentType(''); // Clear selected type if it's being removed
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="dashboard-wrapper mt-4">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="card">
              <h3 className="card-header text-center">KYC Details</h3>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  {/* {/ Document Type Dropdown /} */}
                  <div className="mb-3">
                    <label htmlFor="documentType" className="form-label">Select Document</label>
                    <select
                      id="documentType"
                      name="documentType"
                      className="form-control"
                      value={selectedDocumentType}
                      onChange={(e) => setSelectedDocumentType(e.target.value)}
                      required
                    >
                      <option value="">--Select Document--</option>
                      <option value="aadharCard">Aadhar Card</option>
                      <option value="panCard">PAN Card</option>
                      <option value="gstinCertificate">GSTIN Certificate</option>
                      <option value="cancelledCheque">Cancelled Cheque</option>
                    </select>
                  </div>

                  {/* {/ File Upload for Aadhar Card /} */}
                  {selectedDocumentType === 'aadharCard' && (
                    <div className="mb-3">
                      <label className="form-label">Upload Aadhar Card</label>
                      <input
                        type="file"
                        className="form-control"
                        name="aadharCard"
                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                        onChange={handleFileChange}
                      />
                      {formData.uploadDocuments.aadharCard && (
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <span>{formData.uploadDocuments.aadharCard?.name}</span>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleCancel('aadharCard')}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {/* {/ File Upload for PAN Card /} */}
                  {selectedDocumentType === 'panCard' && (
                    <div className="mb-3">
                      <label className="form-label">Upload PAN Card</label>
                      <input
                        type="file"
                        className="form-control"
                        name="panCard"
                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                        onChange={handleFileChange}
                      />
                      {formData.uploadDocuments.panCard && (
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <span>{formData.uploadDocuments.panCard?.name}</span>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleCancel('panCard')}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {/* {/ File Upload for GSTIN Certificate /} */}
                  {selectedDocumentType === 'gstinCertificate' && (
                    <div className="mb-3">
                      <label className="form-label">Upload GSTIN Certificate</label>
                      <input
                        type="file"
                        className="form-control"
                        name="gstinCertificate"
                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                        onChange={handleFileChange}
                      />
                      {formData.uploadDocuments.gstinCertificate && (
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <span>{formData.uploadDocuments.gstinCertificate?.name}</span>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleCancel('gstinCertificate')}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {/* {/ File Upload for Cancelled Cheque /} */}
                  {selectedDocumentType === 'cancelledCheque' && (
                    <div className="mb-3">
                      <label className="form-label">Upload Cancelled Cheque</label>
                      <input
                        type="file"
                        className="form-control"
                        name="cancelledCheque"
                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                        onChange={handleFileChange}
                      />
                      {formData.uploadDocuments.cancelledCheque && (
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <span>{formData.uploadDocuments.cancelledCheque?.name}</span>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleCancel('cancelledCheque')}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYC;
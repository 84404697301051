import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

interface KYCDetailsFormData {
  providerStoreName: string;
  registeredAddress: string;
  email: string;
  mobileNumber: string;
  panNumber: string;
  gstin?: string;
  fssaiNumber?: string;
}

interface KYCDetailsProps {
  onSubmit: SubmitHandler<KYCDetailsFormData>;
  defaultValues?: KYCDetailsFormData;
  handleBack?: () => void;
}

const KYCDetails: React.FC<KYCDetailsProps> = ({ onSubmit, defaultValues, handleBack }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<KYCDetailsFormData>({ defaultValues });

  return (
    <div className="dashboard-wrapper">
      <div className="container-fluid dashboard-content">
        <div className="row">
          {/* <h2 className="col-12"></h2> */}
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <h2 className="card-header" style={{ textAlign: 'center' }}>KYC Details</h2>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label htmlFor="providerStoreName">Provider Store Name</label>
                    <input
                      id="providerStoreName"
                      {...register('providerStoreName', { required: 'Provider Store Name is required' })}
                      className={`form-control ${errors.providerStoreName ? 'is-invalid' : ''}`}
                    />
                    {errors.providerStoreName && <div className="invalid-feedback">{errors.providerStoreName.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="registeredAddress">Registered Address</label>
                    <input
                      id="registeredAddress"
                      {...register('registeredAddress', { required: 'Registered Address is required' })}
                      className={`form-control ${errors.registeredAddress ? 'is-invalid' : ''}`}
                    />
                    {errors.registeredAddress && <div className="invalid-feedback">{errors.registeredAddress.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: 'Enter a valid email address'
                        }
                      })}
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <input
                      id="mobileNumber"
                      {...register('mobileNumber', {
                        required: 'Mobile Number is required',
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: 'Enter a valid 10-digit mobile number'
                        }
                      })}
                      className={`form-control ${errors.mobileNumber ? 'is-invalid' : ''}`}
                    />
                    {errors.mobileNumber && <div className="invalid-feedback">{errors.mobileNumber.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="panNumber">PAN Number</label>
                    <input
                      id="panNumber"
                      {...register('panNumber', { required: 'PAN number is required' })}
                      className={`form-control ${errors.panNumber ? 'is-invalid' : ''}`}
                    />
                    {errors.panNumber && <div className="invalid-feedback">{errors.panNumber.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="gstin">GSTIN (optional)</label>
                    <input
                      id="gstin"
                      {...register('gstin')}
                      className={`form-control ${errors.gstin ? 'is-invalid' : ''}`}
                    />
                    {errors.gstin && <div className="invalid-feedback">{errors.gstin.message}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="fssaiNumber">FSSAI Number (optional)</label>
                    <input
                      id="fssaiNumber"
                      {...register('fssaiNumber')}
                      className={`form-control ${errors.fssaiNumber ? 'is-invalid' : ''}`}
                    />
                    {errors.fssaiNumber && <div className="invalid-feedback">{errors.fssaiNumber.message}</div>}
                  </div>

                  <div className="button-group mt-3">
                    {handleBack && (
                      <button type="button" onClick={handleBack} className="btn btn-secondary" style={{ marginRight: '10px' }}>
                        Back
                      </button>
                    )}
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCDetails;

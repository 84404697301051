import { BrowserRouter, Route, Routes} from "react-router-dom";
// comman comps
import Login from "../components/comman/Login";
import SignUp from "../components/comman/SignUp";
//user routes
import { Dashboard } from "../components/dashboard/Dashboard";
import Home from "../components/dashboard/Home";
import Vender from "../components/dashboard/Vender";
import AList from "../components/dashboard/AList";
import AddProduct from "../components/dashboard/AddProduct";
import VendorRequestHandler from "../components/dashboard/VendorRequestHandler";
import CouponDashboard from "../components/dashboard/CouponDashboard";
import Wishlist from "../components/dashboard/Whistlist";
import AccountSetting from "../components/dashboard/AccountSetting";
import VendorForm from "../components/dashboard/VendorForm";
import KYC from "../components/dashboard/KYC";
import OtpVerification from "../components/comman/OtpVerification";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* visitor routes */}
        <Route path="" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/otpVerification" element={< OtpVerification/>} />
        {/* admin routes */}
        <Route
          path="dashboard"
          element={<Dashboard />}
        >
          <Route path="" element={<Home />} />
          <Route path="vender" element={<Vender />} />
          <Route path="alist" element={<AList />} />
          <Route path="addProduct" element={<AddProduct />} />
          <Route path="vendorRequestHandler" element={<VendorRequestHandler />} />
          <Route path="couponDashboard" element={<CouponDashboard />} />
          <Route path="wishlist" element={<Wishlist />} />
          <Route path="accountSetting" element={<AccountSetting/>} />
          <Route path="vendorForm" element={<VendorForm />} />
          <Route path="kyc" element={<KYC />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
